<template>
  <div>
    <b-card
      id="customer-form-detail-base"
      ref="customer-form-detail-base"
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
        <b-badge
          class="font-small-4 px-1 py-50"
          :variant="value.is_close_deal ? 'light-danger' : 'light-success'"
        >
          <feather-icon
            size="20"
            :icon="value.is_close_deal ? 'CheckSquareIcon' : 'BookOpenIcon'"
          />
          {{ value.is_close_deal ? 'Đã close deal' : 'Giao dịch đang mở' }}
        </b-badge>
      </div>
      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Hình thức
              </div>
              <span class="detail-text">
                <b-badge variant="primary">
                  {{ (FORMALITY_CUSTOMER.find(item => item.value === value.formality) || {}).text || '' }}
                </b-badge>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Loại
              </div>
              <span class="detail-text">
                <b-badge variant="primary">
                  {{ (TYPE_PRODUCT.find(item => item.value === value.type) || {}).text || '' }}
                </b-badge>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Tỉnh
              </div>
              <span class="detail-text">
                {{ value.province && value.province.name || ((PROVINCE.find(item => item.id === value.province_id) || {}).text || '') }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Quận/Huyện
              </div>
              <span
                v-for="(d, dIdx) in value.districts"
                :key="'customer-detail-district-list-' + d.id"
                class="detail-text"
              ><template v-if="dIdx!==0">,&nbsp;</template>{{ d.name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Phường/Xã
              </div>
              <template
                v-if="value.wards && value.wards.length"
              >
                <span
                  v-for="(w, wIdx) in value.wards"
                  :key="'customer-detail-district-list-' + w.id"
                  class="detail-text"
                ><template v-if="wIdx!==0">,&nbsp;</template>{{ w.name }}
                </span>
              </template>
              <span
                v-else
                class="detail-text"
              >
                {{ value.ward && value.ward.name }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Đường
              </div>
              <span
                v-for="s in value.streets"
                :key="'customer-detail-district-list-' + s.id"
                class="detail-text"
              >
                {{ s.name }}
              </span>
            </div>

            <div
              v-if="value.has_corner"
              class="detail-item"
            >
              <div class="detail-label">
                Đặc biệt
              </div>
              <span
                v-if="value.has_corner"
                class="detail-text"
              >
                Có góc
              </span>
            </div>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Ngang tối thiểu
              </div>
              <span class="detail-text">
                {{ value.width }} <small>m</small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Dài tối thiểu
              </div>
              <span class="detail-text">
                {{ value.length }} <small>m</small>
              </span>
            </div>

            <div
              v-if="value.type !== 'san-thuong'"
              class="detail-item"
            >
              <div class="detail-label">
                Số lầu
              </div>
              <span class="detail-text">
                {{ value.floor_number || '?' }} <strong>-</strong> {{ value.max_floor_number || '?' }} <small>lầu</small>
              </span>
            </div>

            <div
              v-if="value.type === 'khach-san'"
              class="detail-item"
            >
              <div class="detail-label">
                Số phòng
              </div>
              <span class="detail-text">
                {{ value.room_from || '?' }} <strong>-</strong> {{ value.room_to || '?' }} phòng
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Diện tích
              </div>
              <span class="detail-text">
                {{ value.acreage || '?' }} <strong>-</strong> {{ value.max_area || '?' }} <small>m<sup>2</sup></small>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Khoảng giá
              </div>
              <span class="detail-text">
                <span
                  class="mb-1 text-primary"
                >
                  <strong>{{ formatNumber(value.min_price || 0) }}</strong>-<strong>{{ formatNumber(value.max_price || 0) }}</strong>&nbsp;
                </span>
                <small>{{ ((CURRENCY.find(item => item.value === (value.currency || 'million')) || {}).text || '').toLowerCase() }}</small>
              </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            lg="4"
          >
            <div class="detail-item">
              <div class="detail-label">
                Tên Khách + <br>
                Thương Hiệu
              </div>
              <span class="detail-text">
                {{ value.host_name }}
              </span>
            </div>

            <div
              v-if="value.host_phone1"
              class="detail-item"
            >
              <div class="detail-label">
                SĐT
              </div>
              <span class="detail-text">
                {{ value.host_phone1 }}
                <template v-if="value.host_phone2">
                  - {{ value.host_phone2 }}
                </template>
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Nguồn
              </div>
              <span class="detail-text">
                {{ (CUSTOMER_SOURCE.find(item => item.value === value.source) || {}).text || '' }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Tình trạng
              </div>
              <span class="detail-text">
                {{ (STATUS_TYPE_CUSTOMER.find(item => item.value === value.status) || {}).text || '' }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Ngành nghề
              </div>
              <span class="detail-text">
                {{ value.career }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Số lần đã gặp
              </div>
              <span class="detail-text">
                {{ value.meeting_count }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Số lần đã chốt
              </div>
              <span class="detail-text">
                {{ value.confirm_deal_count }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Hẹn khách
              </div>
              <span class="detail-text">
                {{ value.meeting_date ? formatDate(value.meeting_date) : '' }}
              </span>
            </div>

            <div class="detail-item">
              <div class="detail-label">
                Ghi chú
              </div>
              <span class="detail-text">
                {{ value.note }}
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {
      },
    },
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  methods: {},
  setup() {
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';

</style>
